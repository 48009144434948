import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import JobListing from './JobListing';
import { fetchJobList } from '../../../redux/slices/job/jobSlice';
import MainLoader from '../../../shared-components/loader/MainLoader';

function AllJob() {
  const { jobList, totalJobs, isLoading } = useSelector((state) => state.job); // Get jobList and totalJobs from state
  const dispatch = useDispatch();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // Calculate the number of pages based on totalJobs
  const totalPages =Math.ceil(jobList?.count/itemsPerPage);

  useEffect(() => {
    // Fetch jobs whenever currentPage changes
    const payload = `?page=${currentPage}&limit=${itemsPerPage}`;
    dispatch(fetchJobList(payload));
  }, [dispatch, currentPage]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Set the new page number
  };

  return (
    <>
    {isLoading && (<MainLoader />)}
 
    <div className='container mt-4'>
      {/* Display job listings */}
      {jobList?.data?.map((item, index) => (
        <JobListing job={item} key={item?.id || index} />
      ))}

      {/* MUI Pagination Component */}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        shape="rounded"
        variant="outlined"
        sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
      />
    </div>
    </>
  );
}

export default AllJob;
