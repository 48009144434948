// Axios.js
import axios from "axios";
import { BASE_URL } from "../utils/ApiEndPoints";
import { logout } from "../redux/slices/auth/authSlice";

const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
Axios.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem("token");

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor
Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log(error.response.data.message)
    if (error.response && error.response.data && error.response.data.message === 'Invalid token') {
      // Dynamically import the store and dispatch action to avoid circular dependency
      const { store } = await import('../redux/store');
      store.dispatch(logout());
      localStorage.removeItem("token");
     
    }
    return Promise.reject(error);
  }
);

export default Axios;
