import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function ClientTestimonial() {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <h1 className="text-center mb-5">Our Clients Say!!!</h1>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          useKeyboardArrows
          autoPlay
          interval={3000}
          transitionTime={1000}
        >
          <div className="testimonial-item bg-light rounded p-4">
            <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
            <div className="d-flex align-items-center">
              <img
                className="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-1.jpg"
                alt="Client 1"
                style={{ width: '50px', height: '50px' }}
              />
              <div className="ps-3">
                <h5 className="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
          <div className="testimonial-item bg-light rounded p-4">
            <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
            <div className="d-flex align-items-center">
              <img
                className="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-2.jpg"
                alt="Client 2"
                style={{ width: '50px', height: '50px' }}
              />
              <div className="ps-3">
                <h5 className="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
          <div className="testimonial-item bg-light rounded p-4">
            <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
            <div className="d-flex align-items-center">
              <img
                className="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-3.jpg"
                alt="Client 3"
                style={{ width: '50px', height: '50px' }}
              />
              <div className="ps-3">
                <h5 className="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
          <div className="testimonial-item bg-light rounded p-4">
            <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
            <p>Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam</p>
            <div className="d-flex align-items-center">
              <img
                className="img-fluid flex-shrink-0 rounded"
                src="img/testimonial-4.jpg"
                alt="Client 4"
                style={{ width: '50px', height: '50px' }}
              />
              <div className="ps-3">
                <h5 className="mb-1">Client Name</h5>
                <small>Profession</small>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}

export default ClientTestimonial;
