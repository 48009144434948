import React, { useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import FormCardWrapper from '../../../shared-components/cardWrapper/FormCardWrapper';

function JobList() {
  // Sample data for the table
  const data = useMemo(
    () => [
      { jobTitle: 'Software Engineer', jobCategory: 'IT', companyName: 'Tech Co.', vacancies: 5 },
      { jobTitle: 'Product Manager', jobCategory: 'Management', companyName: 'Business Inc.', vacancies: 2 },
      { jobTitle: 'Graphic Designer', jobCategory: 'Design', companyName: 'Creative Studio', vacancies: 3 },
      // Add more rows as needed
    ],
    []
  );

  // Define columns for the table
  const columns = useMemo(
    () => [
      { Header: 'Job Title', accessor: 'jobTitle' },
      { Header: 'Job Category', accessor: 'jobCategory' },
      { Header: 'Company Name', accessor: 'companyName' },
      { Header: 'Vacancies', accessor: 'vacancies' },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            <IconButton color="primary" onClick={() => handleEdit(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleView(row.original)}>
              <VisibilityIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(row.original)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    []
  );

  // Table instance with pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Start on the first page
    },
    usePagination
  );

  // Handle actions (edit, view, delete)
  const handleEdit = (job) => {
    alert(`Edit job: ${job.jobTitle}`);
    // Implement edit functionality here
  };

  const handleView = (job) => {
    alert(`View job: ${job.jobTitle}`);
    // Implement view functionality here
  };

  const handleDelete = (job) => {
    alert(`Delete job: ${job.jobTitle}`);
    // Implement delete functionality here
  };

  return (
    <FormCardWrapper>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    style={{ border: '1px solid #ddd', padding: '8px' }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="primary" onClick={previousPage} disabled={!canPreviousPage}>
          Previous
        </Button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <Button variant="contained" color="primary" onClick={nextPage} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </FormCardWrapper>
  );
}

export default JobList;
