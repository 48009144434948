import React, { useState } from "react";
import {
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { clientCreationSchema } from "../../../utils/validationSchema";
import FormCardWrapper from "../../../shared-components/cardWrapper/FormCardWrapper";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
// import { createClient } from "../../../redux/slices/client/clientSlice";
import { useNavigate } from "react-router-dom";
import { createCompany } from "../../../redux/slices/company/companySlice";

const renderLabel = (label) => (
  <span>
    {label} <span className="error">*</span>
  </span>
);

function ClientCreationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    const payload = {
      companyName: values.companyName,
      companyBio: values.companyBio,
      industry: values.industry,
      companySize: values.companySize,
      website: values.website,
      headquartersAddress: values.headquartersAddress,
      contactNumber: values.contactNumber,
    };

    await dispatch(createCompany(payload))
      .unwrap()
      .then(() => {
        resetForm();
      })
      .catch((error) => {
        console.error(error);
      });

    setIsLoading(false);
  };

  return (
    <FormCardWrapper title={"Create Client"}>
      <CardContent>
        <Formik
          initialValues={{
            companyName: "",
            companyBio: "",
            industry: "",
            companySize: "",
            website: "",
            headquartersAddress: "",
            contactNumber: "",
          }}
          validationSchema={clientCreationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Company Name */}
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="companyName"
                    label={renderLabel("Company Name")}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.companyName && touched.companyName)}
                    helperText={<ErrorMessage name="companyName" />}
                  />
                </Grid>
                {/* Industry */}
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="industry"
                    label={renderLabel("Industry")}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.industry && touched.industry)}
                    helperText={<ErrorMessage name="industry" />}
                  />
                </Grid>

                {/* Company Size */}
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="companySize"
                    label={renderLabel("Company Size")}
                    variant="outlined"
                    type="number"
                    fullWidth
                    error={Boolean(errors.companySize && touched.companySize)}
                    helperText={<ErrorMessage name="companySize" />}
                  />
                </Grid>

                {/* Website */}
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="website"
                    label={"Website"}
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors.website && touched.website)}
                    helperText={<ErrorMessage name="website" />}
                  />
                </Grid>

                {/* Headquarters Address */}
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="headquartersAddress"
                    label={renderLabel("Headquarters Address")}
                    variant="outlined"
                    fullWidth
                    error={Boolean(
                      errors.headquartersAddress && touched.headquartersAddress
                    )}
                    helperText={<ErrorMessage name="headquartersAddress" />}
                  />
                </Grid>

                {/* Contact Number */}
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    name="contactNumber"
                    label={renderLabel("Contact Number")}
                    variant="outlined"
                    fullWidth
                    error={Boolean(
                      errors.contactNumber && touched.contactNumber
                    )}
                    helperText={<ErrorMessage name="contactNumber" />}
                  />
                </Grid>

                {/* Company Bio */}
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    name="companyBio"
                    label={renderLabel("Company Bio")}
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    error={Boolean(errors.companyBio && touched.companyBio)}
                    helperText={<ErrorMessage name="companyBio" />}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 4 }}
                disabled={isLoading}
                endIcon={<SendIcon />}
              >
                {isLoading ? "Loading ..." : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </CardContent>
    </FormCardWrapper>
  );
}

export default ClientCreationForm;
