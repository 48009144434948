import moment from "moment";


  export function getRoleById(id) {
    const roles = {
      1: "admin",
      2: "client",
      3: "candidate",
    };
  
    return roles[id] || "Unknown Role"; // Returns 'Unknown Role' if the id does not match any role
  }
  

  export function formatDateToDDMonthYYYY(dateString) {
    if (!dateString) {
      return dateString;
    }
    return moment(dateString).format('DD-MMM-YYYY');
  }
  