import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { sidebarItems } from "./sidebarItems";
import { Button } from "@mui/material";
import { logout } from "../../redux/slices/auth/authSlice";
import { useDispatch } from "react-redux";

function Sidebar() {
  const [openItems, setOpenItems] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get current location

  // Handle click to toggle collapse for specific items
  const handleClick = (itemName) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [itemName]: !prevOpenItems[itemName],
    }));
  };

  const handleLogout = () => {
    // Clear tokens from localStorage
    localStorage.removeItem("token");

    // Dispatch the logout action to reset state
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div
      style={{
        width: "300px",
        padding: "10px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Full height of viewport
      }}
    >
      <List style={{ flexGrow: 1 }}>
        {sidebarItems.map((item, index) => (
          <div key={index}>
            {/* Wrap ListItem with NavLink and use selected prop */}
            <ListItem
              button
              component={item.collapsible ? "div" : NavLink}
              to={!item.collapsible ? item.route : undefined}
              onClick={() => item.collapsible && handleClick(item.name)}
              selected={!item.collapsible && location.pathname === item.route} // Highlight active route
              sx={{ color: location.pathname === item.route ? "primary.main" : "inherit" }} // Optional: Change color for active link
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
              {item.collapsible ? (
                openItems[item.name] ? <ExpandLess /> : <ExpandMore />
              ) : null}
            </ListItem>
            {item.collapsible && (
              <Collapse in={openItems[item.name]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.items.map((subItem, subIndex) => (
                    <ListItem
                      button
                      component={NavLink}
                      to={subItem.route}
                      key={subIndex}
                    
                      selected={location.pathname === subItem.route} // Highlight active sub-route
                      sx={{ color: location.pathname === subItem.route ? "primary.main" : "inherit", pl:6 }} // Optional: Change color for active sub-link
                    >
                      <ListItemIcon>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={subItem.name} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      {/* Place the Logout button at the bottom */}
      <Button variant="contained" sx={{ mb: 4 }} onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
}

export default Sidebar;
