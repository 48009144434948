import React from 'react';
import { CONSTANT_NAME } from '../../utils/PropertyResolver';

export default function TermsAndConditions() {
    return (
        <>
        <div className="d-flex justify-content-center mt-3 mb-3">

            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="mb-4">Terms and Conditions for MV Hiring</h1>
                <p className="mb-4">
                    Welcome to MV Hiring. By accessing and using our website and services, you agree to comply with and be bound by the following terms and conditions.
                </p>
                <p>
                    <strong>1. Acceptance of Terms:</strong> By using our services, you accept these terms and conditions in full. If you disagree with any part of these terms, you must not use our services.
                </p>
                <p>
                    <strong>2. User Responsibilities:</strong> You agree to provide accurate and complete information when using our services and to update your details as necessary. You are responsible for maintaining the confidentiality of your account and password.
                </p>
                <p>
                    <strong>3. Use of Services:</strong> Our services are provided solely for the purpose of connecting job seekers with potential employers. Any misuse of our services for unlawful or unethical purposes is strictly prohibited.
                </p>
                <p>
                    <strong>4. Intellectual Property:</strong> All content on this website, including text, graphics, logos, and software, is the property of MV Hiring or its licensors and is protected by applicable intellectual property laws. You may not use, copy, or distribute any content without prior written permission.
                </p>
                <p>
                    <strong>5. Limitation of Liability:</strong> MV Hiring shall not be held liable for any damages arising from your use of our services, including but not limited to, indirect or consequential damages, or loss of data or profits.
                </p>
                <p>
                    <strong>6. Termination:</strong> We reserve the right to suspend or terminate your access to our services at any time, with or without notice, for any violation of these terms or applicable laws.
                </p>
                <p>
                    <strong>7. Governing Law:</strong> These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction where MV Hiring operates, without regard to its conflict of law provisions.
                </p>
                <p>
                    <strong>8. Changes to Terms:</strong> MV Hiring reserves the right to update these terms and conditions at any time. Please review them periodically to stay informed of any changes.
                </p>
                <p>
                    By continuing to use our services, you acknowledge that you have read, understood, and agreed to the above terms and conditions. If you have any questions or concerns, please contact us at {CONSTANT_NAME.EMAIL_ID}.
                </p>
            </div>
            </div>
        </>
    );
}
