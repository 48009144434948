import React from 'react';
import AboutGallery from './AboutGallery';

export default function AboutUs() {
    return (
        <>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="mb-4">At MV Hiring, we are dedicated to connecting talent with opportunity.</h1>
                <p className="mb-4">
                    Specializing in recruitment, staffing, and contract-based solutions, we provide businesses with the human capital they need to thrive in a competitive market.
                </p>
                <p>
                    Our recruitment services are designed to identify top-tier candidates who not only possess the right skills but also align with your company’s culture and values. We meticulously screen and evaluate each candidate to ensure the perfect fit for your organization, whether you're seeking full-time employees, temporary staff, or highly specialized contract-based professionals.
                </p>
                <p>
                    With extensive industry knowledge and a commitment to excellence, MV Hiring is your trusted partner in building a workforce that drives success. We understand the unique challenges of finding the right talent and are here to simplify the process, delivering tailored staffing solutions that meet your specific needs.
                </p>
                <p>
                    Whether you're looking to expand your team, fill a short-term gap, or require expert professionals for project-based roles, MV Hiring is here to support your growth. Our personalized approach ensures that you receive the highest level of service, with a focus on quality, efficiency, and long-term partnerships.
                </p>
                <p>
                    Choose MV Hiring and experience a seamless, efficient, and effective hiring process. Let us help you build a brighter future, one exceptional hire at a time.
                </p>
            </div>
        </>
    );
}
