import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import CreateJob from './job/CreateJob';
import JobCategories from './job/component/JobCategories';
import JobList from './job/component/JobList';
import ContactUs from './contactUs/ContactUs';
import CreateClient from './client/CreateClient';
import ApplicationList from './application/ApplicationList';

function MainLayout() {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar Section */}
      <Sidebar />

      {/* Main Content Section */}
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
        <Routes>
          <Route path="add-job" element={<CreateJob />} />
          <Route path="add-job-category" element={<JobCategories />} />
          <Route path="job-list" element={<JobList />} />
          <Route path="contact-list" element={<ContactUs />} />
          <Route path="create-company" element={<CreateClient />} />
          <Route path="job-application" element={<ApplicationList />} />


          {/* Default route can redirect or show a default component */}
          <Route path="/" element={<CreateJob />} /> 
        </Routes>
      </div>
    </div>
  );
}

export default MainLayout;
