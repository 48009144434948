import React from 'react'
import CategoryCard from '../../shared-components/categoryCard/CategoryCard'
import { useSelector } from 'react-redux';

export default function Explore() {
  const { jobCategories } = useSelector((state) => state.job);

    return (
        <div class="container-xxl py-5">
            <div class="container">
                <h1 class="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Explore By Category</h1>
                <div class="row g-4">
                    {
                        jobCategories?.map((item, index)=>(
                            <CategoryCard data={item} key={item?.id || index}/>
                        ))
                    }
               
                </div>
            </div>
        </div>
    )
}
