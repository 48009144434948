import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { fetchAppliedJobs } from '../redux/actions/jobActions'; // Replace with your actual action
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import MainLoader from '../../shared-components/loader/MainLoader';
import { fetchAppliedJobs } from '../../redux/slices/job/jobSlice';
import FormCardWrapper from '../../shared-components/cardWrapper/FormCardWrapper';

function AppliedJobsTable() {
  const dispatch = useDispatch();
  const { appliedJobs, isLoading, error } = useSelector((state) => state.job);
   
    
  useEffect(() => {
    dispatch(fetchAppliedJobs());
  }, [dispatch]);

  

  return (
    <>
   <FormCardWrapper>
    <TableContainer >
      <Typography variant="h6" sx={{ p: 2 }}>
        Applied Jobs
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Job Title</TableCell>
            <TableCell align='center'>Company</TableCell>
            <TableCell align='center'>Application Date</TableCell>
            <TableCell align='center'>Status</TableCell>
            {/* <TableCell align="right">Actions</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
               <MainLoader />
              </TableCell>
            </TableRow>
          ) : error ? (
            <TableRow>
              <TableCell colSpan={5} align="center" color="error">
                {error}
              </TableCell>
            </TableRow>
          ) : appliedJobs?.length > 0 ? (
            appliedJobs?.map((job) => (
              <TableRow key={job?.id}>
                <TableCell align="center">{job?.job?.jobTitle}</TableCell>
                <TableCell align="center">{job?.job?.client?.companyName}</TableCell>
                <TableCell align="center">{new Date(job?.createdAt).toLocaleDateString()}</TableCell>
                <TableCell align="center">{job?.status}</TableCell>
                {/* <TableCell align="right">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleViewDetails(job.id)}
                    sx={{ mr: 1 }}
                  >
                    View Details
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="error"
                    onClick={() => handleWithdraw(job.id)}
                  >
                    Withdraw
                  </Button>
                </TableCell> */}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No applied jobs found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    </FormCardWrapper>
    </>
  );

}

export default AppliedJobsTable;
