// export const BASE_URL = `http://localhost:4000`;
export const BASE_URL = `https://job-portal-be-19v8.onrender.com`;



export const API_END_POINT = {
    LOGIN: '/auth/login',
    REGISTER:'/auth/register',
    COMPLETE_PROFILE: '/user/complete-profile',
    PROFILE: '/user/userinfo',
    GET_ALL_JOB_CATEGORIES:'/job/category',
    GET_ALL_JOBS: "/job/jobs",
    GET_JOB_BY_ID: "/job/jobs",
    CREATE_JOB: "/job/create",
    CREATE_JOB_CATEGORY:'/job/category-create',
    GET_ALL_COMPANIES: '/company/get-company',
    CREATE_CONTACT:'/contact/create',
    JOB_APPLY:"/application/apply",
    GET_APPLIED_JOBS: "/application/my-applied-job",
    FORGOT_PASSWORD:"/auth/forgot",
    RESET_PASSWORD: "/auth/reset",
    CREATE_COMPANY:"/company/create-company",
    APPLIED_JOBS:"/application/all-applied-job",
    UPDATE_JOB_STATUS:"/application/update-status"
}