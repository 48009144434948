import React from "react";
import { formatDateToDDMonthYYYY } from "../../../utils/CommonMethod";
import { Link, useNavigate } from "react-router-dom";

function JobListing({ job }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/job/${job?.id}`);
  };
  return (
    <>
      <div className="job-item p-4 mb-4 cursor-pointer" onClick={handleClick}>
        <div className="row g-4">
          <div className="col-sm-12 col-md-8 d-flex align-items-center">
            {/* <img
              className="flex-shrink-0 img-fluid border rounded"
              src={job?.client?.companyLogo}
              alt=""
              style={{ width: "80px", height: "80px" }}
            /> */}
            <div className="text-start ps-4">
              <h5 className="mb-3">{job?.jobTitle}</h5>
              <span className="text-truncate me-3">
                <i className="fa fa-map-marker-alt text-primary me-2"></i>
                {job?.jobLocation}
              </span>
              <span className="text-truncate me-3">
                <i className="far fa-clock text-primary me-2"></i>
                {job?.totalVacancy}
              </span>
              <span className="text-truncate me-0">
                <i className="far fa-money-bill-alt text-primary me-2"></i>
                {job?.salaryRange}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
            <div className="d-flex mb-3">
              {/* <a className="btn btn-light btn-square me-3" href="">
                <i className="far fa-heart text-primary"></i>
              </a> */}
              <Link className="btn btn-primary" to={`/job/${job?.id}`}>
                Apply Now
              </Link>
            </div>
            <small className="text-truncate">
              <i className="far fa-calendar-alt text-primary me-2"></i>
              {formatDateToDDMonthYYYY(job?.lastDate)}
            </small>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobListing;
