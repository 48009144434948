import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/slices/auth/authSlice";
import toast from "react-hot-toast";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const isDisable = !email.trim() || !password.trim();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { isLoading } = useSelector((state) => state.auth);
  const { userProfile } = useSelector((state) => state.user);

  const handleLogin = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      password,
    };
    await dispatch(loginUser(payload, navigate))
      .unwrap()
      .then(() => {
        // On success, clear the form fields
        setEmail("");
        setPassword("");

        navigate("/");
      })
      .catch((error) => {});
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2 className="login-title">Login</h2>
        <form className="login-form" onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">
              Email <span className="error">*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">
              Password <span className="error">*</span>
            </label>
            <div className="password-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Enter your password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="toggle-password"
                onClick={togglePasswordVisibility}
              >
                <i
                  className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
                ></i>
              </span>
            </div>
          </div>
          <button
            type="submit"
            className={
              isDisable || isLoading ? "login-button-disable" : "login-button"
            }
            disabled={isDisable}
          >
            {isLoading ? "Loading..." : "Login"}
          </button>
        </form>
        <div className="login-links">
          <Link to="/forgot-password">Forgot Password?</Link>
          <Link to="/register">Create a New Account</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
