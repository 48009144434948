import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const companyState = {
  isLoading: false,
  error: null,
  companyCategories: [],
  companyList: [],
  companyDetails: {},
};

// Fetch all company categories
export const fetchCompanyCategories = createAsyncThunk(
  "company/fetchCompanyCategories",
  async (_, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_COMPANY_CATEGORIES);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_COMPANY_CATEGORIES_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_COMPANY_CATEGORIES_FAILED);
    }
  }
);

// Create a new company category
export const createCompanyCategory = createAsyncThunk(
  "company/createCompanyCategory",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_COMPANY_CATEGORY, payload);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_COMPANY_CATEGORY_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.CREATE_COMPANY_CATEGORY_FAILED);
    }
  }
);

// Fetch all companies
export const fetchCompanyList = createAsyncThunk(
  "company/fetchCompanyList",
  async (_, thunkAPI) => {
    try {
      const response = await GET(API_END_POINT.GET_ALL_COMPANIES);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_COMPANY_LIST_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_COMPANY_LIST_FAILED);
    }
  }
);

// Fetch company by ID
export const fetchCompanyById = createAsyncThunk(
  "company/fetchCompanyById",
  async (companyId, thunkAPI) => {
    try {
      const response = await GET(`${API_END_POINT.GET_COMPANY_BY_ID}/${companyId}`);
      if (response?.status === 200) {
        return response.response?.data?.data;
      } else {
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_COMPANY_BY_ID_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_COMPANY_BY_ID_FAILED);
    }
  }
);

// Create a new company
export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (companyData, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.CREATE_COMPANY, companyData);
      if (response?.status === 200) {
        toast.success("Company created successfully!");
        return response.response?.data?.data;
      } else {
        toast.error(response.response?.data?.message)
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Company slice
const companySlice = createSlice({
  name: "company",
  initialState: companyState,
  reducers: {
    resetCompanyState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.companyCategories = [];
      state.companyList = [];
      state.companyDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch company categories
      .addCase(fetchCompanyCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyCategories = action.payload;
      })
      .addCase(fetchCompanyCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_COMPANY_CATEGORIES_FAILED;
      })

      // Fetch company list
      .addCase(fetchCompanyList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyList = action.payload;
      })
      .addCase(fetchCompanyList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_COMPANY_LIST_FAILED;
      })

      // Fetch company by ID
      .addCase(fetchCompanyById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyDetails = action.payload;
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.FETCH_COMPANY_BY_ID_FAILED;
      })
  },
});

export const { resetCompanyState } = companySlice.actions;

export default companySlice.reducer;
