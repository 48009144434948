import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler";
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";

// Define the initial state
const jobApplicationState = {
  isLoading: false,
  error: null,
  appliedJobList: {
    totalCount: 0,
    data: [],
  },
};

// Fetch applied job list with pagination
export const fetchAppliedJobList = createAsyncThunk(
  "jobApplication/fetchAppliedJobList",
  async ({ page, limit }, thunkAPI) => {
    try {
      const response = await GET(
        `${API_END_POINT.APPLIED_JOBS}?page=${page}&limit=${limit}`
      );
      if (response?.status === 200) {
        const data = {
          totalCount: response.response?.data?.data?.totalApplications || 0,
          data: response.response?.data?.data?.applications || [],
        };
        return data;
      } else {
        return thunkAPI.rejectWithValue(
          ERROR_MESSAGE.FETCH_APPLIED_JOBS_FAILED
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.FETCH_APPLIED_JOBS_FAILED);
    }
  }
);

export const updateJobStatus = createAsyncThunk(
  "jobApplication/updateJobStatus",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.UPDATE_JOB_STATUS, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.error);
        return response.response?.data?.data?.data;
      } else {
        toast.error(response.response?.data?.error);
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Job application slice
const jobApplicationSlice = createSlice({
  name: "jobApplication",
  initialState: jobApplicationState,
  reducers: {
    resetJobApplicationState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.appliedJobList = {
        totalCount: 0,
        data: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch applied job list
      .addCase(fetchAppliedJobList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAppliedJobList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.appliedJobList = action.payload;
      })
      .addCase(fetchAppliedJobList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.SOMETHING_WENT_WRONG;
      });
  },
});

export const { resetJobApplicationState } = jobApplicationSlice.actions;

export default jobApplicationSlice.reducer;
