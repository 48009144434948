import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET, POST } from "../../../services/axiosRequestHandler"; // Adjust this according to your POST method setup
import { ERROR_MESSAGE } from "../../../utils/PropertyResolver";
import { API_END_POINT } from "../../../utils/ApiEndPoints";
import toast from "react-hot-toast";
import { fetchUserProfile } from "../user/userSlice";

// Define the initial state
const authState = {
  isLoading: false,
  error: null,
  isAuthenticated: false,
};

// Login user
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (payload, thunkAPI) => {
   

    try {
      const response = await POST(API_END_POINT.LOGIN, payload);
      if (response?.status === 200) {
        localStorage.setItem("token", response.response?.data?.data?.token);
        const userProfile = await GET(API_END_POINT.PROFILE);

        // if (userProfile.response?.data?.data?.userRole === 1) {
        //   return navigate("/admin");
        // } else {
        //   return navigate("/");
        // }
        return response.response?.data?.data;
      } else {
        console.log(response?.response?.data);
        toast.error(
          response?.response?.data?.error || ERROR_MESSAGE.LOGIN_FAILED
        );
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.LOGIN_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.LOGIN_FAILED);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.FORGOT_PASSWORD, email);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(
          response?.response?.data?.error || ERROR_MESSAGE.SOMETHING_WENT_WRONG
        );
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.RESET_PASSWORD, payload);
      if (response?.status === 200) {
        toast.success(response.response?.data?.message);
        return response.response?.data?.data;
      } else {
        toast.error(
          response?.response?.data?.error || ERROR_MESSAGE.SOMETHING_WENT_WRONG
        );
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.SOMETHING_WENT_WRONG);
    }
  }
);

// Register user
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, thunkAPI) => {
    try {
      const response = await POST(API_END_POINT.REGISTER, userData);
      if (response?.status === 200) {
        toast.success("Registration successful");
        return response.response?.data?.data;
      } else {
        toast.error(response?.response?.data?.error || "Registration failed");
        return thunkAPI.rejectWithValue(ERROR_MESSAGE.REGISTRATION_FAILED);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(ERROR_MESSAGE.REGISTRATION_FAILED);
    }
  }
);



// Auth slice
const authSlice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    resetAuthState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = false;
      localStorage.removeItem("token");
    },
    logout(state) {
      return authState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.LOGIN_FAILED;
        state.isAuthenticated = false;
      })
      // Register
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || ERROR_MESSAGE.REGISTRATION_FAILED;
        state.isAuthenticated = false;
      })
      
  },
});

export const { resetAuthState, logout } = authSlice.actions;

export default authSlice.reducer;
