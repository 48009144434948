import React from "react";
import {
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Chip,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { profileCompleteSchema } from "../../../utils/validationSchema";
import FormCardWrapper from "../../../shared-components/cardWrapper/FormCardWrapper";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch } from "react-redux";
import { completeProfile } from "../../../redux/slices/user/userSlice";
import { useNavigate } from "react-router-dom";

const renderLabel = (label) => (
  <span>
    {label} <span className="error">*</span>
  </span>
);

function CandidateProfileForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Handle form submission
  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      dateOfBirth: values?.dateOfBirth,
      gender: values?.gender,
      address: values?.address,
      highestQualification: values?.highestQualification,
      fieldOfStudy: values?.fieldOfStudy,
      universityName: values?.universityName,
      yearOfGraduation: values?.yearOfGraduation,
      totalExperience: values?.totalExperience,
      currentEmployer: values?.currentEmployer,
      jobTitle: values?.jobTitle,
      noticePeriod: values?.noticePeriod,
      currentSalary: values?.currentSalary,
      expectedSalary: values?.expectedSalary,
      keySkills: values?.keySkills,
      certifications: values?.certifications,
    };
    dispatch(completeProfile(payload))
      .unwrap()
      .then(() => {
        resetForm();
        navigate("/");
      })
      .catch((error) => {});
  };

  return (
    <FormCardWrapper title={"Profile Completion"}>
      <CardContent>
        <Formik
          initialValues={{
            dateOfBirth: "",
            gender: "",
            address: "",
            highestQualification: "",
            fieldOfStudy: "",
            universityName: "",
            yearOfGraduation: "",
            totalExperience: "",
            currentEmployer: "",
            jobTitle: "",
            noticePeriod: "",
            currentSalary: "",
            expectedSalary: "",
            keySkills: [],
            certifications: [],
          }}
          validationSchema={profileCompleteSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue, errors, touched }) => {
            const handleKeyDown = (e, field) => {
              if (e.key === "Enter" && e.target.value) {
                e.preventDefault();
                const newValue = e.target.value;
                setFieldValue(field, [...values[field], newValue]);
                e.target.value = "";
              }
            };

            const handleDelete = (item, field) => {
              setFieldValue(
                field,
                values[field].filter((i) => i !== item)
              );
            };

            return (
              <Form>
                <Grid container spacing={2}>
                  {/* Date of Birth */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="dateOfBirth"
                      label={renderLabel("Date of Birth")}
                      type="date"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
                      helperText={<ErrorMessage name="dateOfBirth" />}
                    />
                  </Grid>

                  {/* Gender */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      select
                      name="gender"
                      label={renderLabel("Gender")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.gender && touched.gender)}
                      helperText={<ErrorMessage name="gender" />}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Field>
                  </Grid>

                  {/* Address */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="address"
                      label="Address"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.address && touched.address)}
                      helperText={<ErrorMessage name="address" />}
                    />
                  </Grid>

                  {/* Highest Qualification */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="highestQualification"
                      label={renderLabel("Highest Qualification")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.highestQualification &&
                          touched.highestQualification
                      )}
                      helperText={<ErrorMessage name="highestQualification" />}
                    />
                  </Grid>

                  {/* Field of Study */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="fieldOfStudy"
                      label={renderLabel("Field of Study")}
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.fieldOfStudy && touched.fieldOfStudy
                      )}
                      helperText={<ErrorMessage name="fieldOfStudy" />}
                    />
                  </Grid>

                  {/* University Name */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="universityName"
                      label="University Name"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.universityName && touched.universityName
                      )}
                      helperText={<ErrorMessage name="universityName" />}
                    />
                  </Grid>

                  {/* Year of Graduation */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="yearOfGraduation"
                      label="Year of Graduation"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={Boolean(
                        errors.yearOfGraduation && touched.yearOfGraduation
                      )}
                      helperText={<ErrorMessage name="yearOfGraduation" />}
                    />
                  </Grid>

                  {/* Total Experience */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="totalExperience"
                      label="Total Experience (years)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={Boolean(
                        errors.totalExperience && touched.totalExperience
                      )}
                      helperText={<ErrorMessage name="totalExperience" />}
                    />
                  </Grid>

                  {/* Current Employer */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="currentEmployer"
                      label="Current Employer"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.currentEmployer && touched.currentEmployer
                      )}
                      helperText={<ErrorMessage name="currentEmployer" />}
                    />
                  </Grid>

                  {/* Job Title */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="jobTitle"
                      label="Job Title"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.jobTitle && touched.jobTitle)}
                      helperText={<ErrorMessage name="jobTitle" />}
                    />
                  </Grid>

                  {/* Notice Period */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="noticePeriod"
                      label="Notice Period"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.noticePeriod && touched.noticePeriod
                      )}
                      helperText={<ErrorMessage name="noticePeriod" />}
                    />
                  </Grid>

                  {/* Current Salary */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="currentSalary"
                      label="Current Salary"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.currentSalary && touched.currentSalary
                      )}
                      helperText={<ErrorMessage name="currentSalary" />}
                    />
                  </Grid>

                  {/* Expected Salary */}
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={TextField}
                      name="expectedSalary"
                      label="Expected Salary"
                      variant="outlined"
                      fullWidth
                      error={Boolean(
                        errors.expectedSalary && touched.expectedSalary
                      )}
                      helperText={<ErrorMessage name="expectedSalary" />}
                    />
                  </Grid>

                  {/* Key Skills */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="keySkillsInput"
                      label="Key Skills"
                      variant="outlined"
                      fullWidth
                      onKeyDown={(e) => handleKeyDown(e, "keySkills")}
                    />
                    <div>
                      {values.keySkills.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          onDelete={() => handleDelete(skill, "keySkills")}
                          color="primary"
                          style={{ margin: "5px" }}
                        />
                      ))}
                    </div>
                  </Grid>

                  {/* Certifications */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="certificationsInput"
                      label="Certifications"
                      variant="outlined"
                      fullWidth
                      onKeyDown={(e) => handleKeyDown(e, "certifications")}
                    />
                    <div>
                      {values.certifications.map((cert, index) => (
                        <Chip
                          key={index}
                          label={cert}
                          onDelete={() => handleDelete(cert, "certifications")}
                          color="secondary"
                          style={{ margin: "5px" }}
                        />
                      ))}
                    </div>
                  </Grid>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mt: 4 }}
                  disabled={isSubmitting}
                  endIcon={<SendIcon />}
                >
                  {isSubmitting ? "Loading ..." : "Submit"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </CardContent>
    </FormCardWrapper>
  );
}

export default CandidateProfileForm;
