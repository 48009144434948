// ContactUs.js
import React, { useState, useCallback } from "react";
import axios from "axios";
import Table from "../../shared-components/table/Table";

function ContactUs() {
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const fetchData = useCallback(({ pageIndex, pageSize }) => {
    axios
      .get(`http://localhost:4000/contact/contactList?page=${pageIndex + 1}&limit=${pageSize}`)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.data);
          setPageCount(Math.ceil(response.data.total / pageSize));
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleMarkAsRead = (id) => {
    console.log("Marked as read:", id);
    // Add API call here to mark as read
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Message",
      accessor: "message",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
  ];

  // Define the buttons you want in the table's action column
  const actionButtons = [
    {
      label: "Mark as Read",
      onClick: (row) => handleMarkAsRead(row.id),
      variant: "contained",
      color: "primary",
    },
    {
        label: "View",
        onClick: (row) => handleMarkAsRead(row.id),
        variant: "contained",
        color: "primary",
      },
  ];

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
      <Table
        columns={columns}
        data={data}
        fetchData={fetchData}
        pageCount={pageCount}
        actionButtons={actionButtons} // Pass button configurations as props
      />
    </div>
  );
}

export default ContactUs;
