import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRoleById } from "./utils/CommonMethod";

const ProtectedRoute = ({ element: Component, roles }) => {
  const { userProfile } = useSelector((state) => state.user);
  console.log(userProfile)
  if (!userProfile?.userRole) {
    return <Navigate to="/login" />;
  }
  const user = {
    role: getRoleById(userProfile?.userRole),
  };
  if(!userProfile?.isProfileComplete){
    return <Navigate to="/profile-complete" />;
  }
  
  if (!roles.includes(user.role)) {
    // If the user doesn't have the required role, Navigate to unauthorized page or home
    return <Navigate to="/unauthorized" />;
  }

  // If the user has the required role, render the component
  return Component;
};

export default ProtectedRoute;
