import React, { useEffect, useState } from 'react'
import JobFilter from './component/JobFilter'
import JobListing from './component/JobListing'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchJobList } from '../../redux/slices/job/jobSlice';

function Job() {
  const [jobType, setJobType] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const dispatch = useDispatch();
  const { jobList } = useSelector((state) => state.job);
  useEffect(()=>{
    
    const payload = `?page=${page}&limit=${limit}&jobType=${jobType}`
    dispatch(fetchJobList(payload))
  }, [dispatch, jobType] );

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <h1 className="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">Job Listing</h1>
        <div className="tab-className text-center wow fadeInUp" data-wow-delay="0.3s">
          <JobFilter setJobType={setJobType}/>
        </div>
        <div className="tab-content">
          <div id="tab-1" className="tab-pane fade show p-0 active">
            {
              jobList?.data?.map((item, index)=>(
                <JobListing job={item} key={item?.id || index} />
              ))
            }
      <div className='d-flex justify-content-center align-items-center'>
            <Link class="btn btn-primary py-3 px-5 text-center fadeInUp" to={"/job-list"}>Browse More Jobs</Link>
            </div>

          </div>
          
        </div>
        
      </div>
     
    </div>
  )
}

export default Job
