import * as Yup from "yup";

export const profileCompleteSchema = Yup.object({
  dateOfBirth: Yup.date()
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future"),
  gender: Yup.string()
    .oneOf(["male", "female", "other"], "Invalid Gender")
    .required("Gender is required"),
  address: Yup.string(),
  highestQualification: Yup.string().required(
    "Highest Qualification is required"
  ),
  fieldOfStudy: Yup.string().required("Field of Study is required"),
  universityName: Yup.string(),
  yearOfGraduation: Yup.number()
    .min(1900, "Year must be after 1900")
    .max(
      new Date().getFullYear(),
      `Year must be ${new Date().getFullYear()} or earlier`
    )
    .nullable(true),
  totalExperience: Yup.number()
    .min(0, "Experience must be a positive number")
    .nullable(true),
  currentEmployer: Yup.string(),
  jobTitle: Yup.string(),
  noticePeriod: Yup.string(),
  currentSalary: Yup.string(),
  expectedSalary: Yup.string(),
  keySkills: Yup.array().of(Yup.string()),
  certifications: Yup.array().of(Yup.string()),
});

export const jobCreationSchema = Yup.object().shape({
  jobTitle: Yup.string().required("Job Title is required"),
  jobLocation: Yup.string().required("Job Location is required"),
  jobType: Yup.string().required("Job Type is required"),
  salaryRange: Yup.string().required("Salary Range is required"),
  jobDescription: Yup.string().required("Job Description is required"),
  responsibility: Yup.string().required("Responsibility is required"),
  qualification: Yup.string().required("Qualification is required"),
  totalVacancy: Yup.number()
    .required("Total Vacancy is required")
    .min(1, "There should be at least 1 vacancy"),
  lastDayToApply: Yup.date().required("Last Day to Apply is required"),
  company: Yup.string().required("Company is required"),
  category: Yup.string().required("Category is required"),
});

export const clientCreationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  companyBio: Yup.string().required("Company bio is required"),
  industry: Yup.string().required("Industry is required"),
  companySize: Yup.number()
    .integer("Company size must be an integer")
    .required("Company size is required"),
  website: Yup.string().url("Enter a valid URL").optional(),
  headquartersAddress: Yup.string().optional(),
  contactNumber: Yup.string().optional(),
});
