import React from 'react';

export default function PrivacyPolicy() {
    return (
        <>
        <div className="d-flex justify-content-center mt-3 mb-3">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h1 className="mb-4">Privacy Policy for MV Hiring</h1>
                <p className="mb-4">
                    At MV Hiring, your privacy is of paramount importance to us. This privacy policy outlines how we handle and protect your personal information when you interact with our services.
                </p>
                <p>
                    We collect personal data such as name, contact details, and employment history to match candidates with appropriate job opportunities. Rest assured, all information is collected with your explicit consent and used solely for recruitment purposes.
                </p>
                <p>
                    We implement rigorous security measures to protect your data from unauthorized access, alteration, or disclosure. Your personal information is stored securely and is only accessible to authorized personnel involved in the hiring process.
                </p>
                <p>
                    MV Hiring does not share your personal information with third parties without your prior consent unless required by law or as necessary to facilitate the recruitment process with your potential employer.
                </p>
                <p>
                    You have the right to request access to the personal data we hold about you, and to request corrections or deletions where applicable. If you have any concerns about how we manage your data, please contact us at support@mvhiring.com.
                </p>
                <p>
                    By using our services, you consent to the collection and use of your information as described in this privacy policy. We may update this policy periodically, so please review it regularly to stay informed about how we protect your privacy.
                </p>
                <p>
                    Thank you for trusting MV Hiring with your personal information. We are committed to safeguarding your privacy throughout the recruitment process.
                </p>
            </div>
            </div>
        </>
    );
}
